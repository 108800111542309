<template>
  <base-section
    id="banner"
    class="pt-2 pb-3"
  >
    <div class="banner-text text-center  white--text banner">
      breakingschool.ch
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style>
.banner-text{
  font-weight: 900;
  word-spacing: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 2.3rem;

}
.banner{
  width:100%;
  height:28px;
  white-space: nowrap;
  overflow-x: hidden!important;
  font-size: calc(10px + (26 - 14) * ((100vw - 100px) / (1600 - 100)))!important;
}

#banner{
  background-color: #e62a29 !important;
  padding-top: 5px
}
</style>
